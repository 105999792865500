
.Button:hover{
    background-color: #235E95;
    color: #F1F5F9;
}

.Button.outline:hover{
    background-color: #E3EBF2;
    color: #235E95;
}

.Button.ghost:hover{
    background-color: transparent;
    color: #235E95;
    text-decoration: underline;
}