.team-select-button.selected{
    color: white;
    background-color: rgba(43, 108, 176, 0.66);
}
.team-select-button.selected.correct{
    background-color: rgba(47, 133, 90, 0.66);
}
.team-select-button.selected.incorrect{
    background-color: rgba(229, 62, 62, 0.66);
}

.team-select-button.selected:hover{
    background-color: rgba(43, 108, 176, 0.75);
}
