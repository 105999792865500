.nav .nav-list .nav-item{
    min-width: 120px;

    outline: 2px solid transparent;
    outline-offset: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    font-size: var(--chakra-fontSizes-md);
    padding-top: var(--chakra-space-2);
    padding-bottom: var(--chakra-space-2);
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    border-bottom: 2px solid;
        border-bottom-color: currentcolor;
    border-color: var(--chakra-colors-transparent);
    margin-bottom: -2px;
    color: var(--tabs-color);
    background: var(--tabs-bg);
}

.nav .nav-list .nav-item[aria-selected="true"]{
    --tabs-color: unset;
    border-color: var(--chakra-colors-transparent);
}

.nav .nav-list .nav-item[aria-current="page"]{
    --tabs-color: var(--chakra-colors-blue-600);
    border-color: currentColor;
}


.nav .nav-list .nav-item:focus-visible{
    box-shadow: none;
}